import {
  Market,
  TextField,
  capitalize,
  Anchor,
  Select,
  Button,
  toMonthName,
  InfoMessage,
  useAuth,
  Zone,
} from '@ecommerce/shared'
import React, { useState, useEffect } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { navigate } from 'gatsby'

interface Props {
  isLoading: boolean
  markets: Market[]
  onCityChange: (selected: string) => {}
  onMarketChange: (selected: string) => {}
  currentSlug: string
  cssPrefix: string
  bdate: {
    date: string
    month: string
    year: string
  }
  globalQuantity: number
  apiError: string | null
  setAlertState: ({ isOpenSignOut, isOpenSubmit }: { isOpenSignOut: boolean; isOpenSubmit: boolean }) => void
  alertState: {
    isOpenSignOut: boolean
    isOpenSubmit: boolean
  }
  handleSubmit: () => void
}

const EditChile = ({
  alertState,
  setAlertState,
  apiError,
  cssPrefix,
  markets,
  isLoading,
  bdate,
  currentSlug,
  onCityChange,
  onMarketChange,
  globalQuantity,
  handleSubmit,
}: Props) => {
  const methods = useFormContext()

  const { state: authState } = useAuth()
  const { city, market } = authState

  const [region, setRegion] = useState(market || 0)
  const [cities, setCities] = useState<Zone[] | undefined>(region ? markets.find((mk) => mk.id === region)?.zones : [])
  const [selectedCity, setSelectedCity] = useState(cities?.find((ct) => ct.id === city)?.name || '')

  const { control, formState } = methods

  const { dirtyFields, isValid } = formState

  const onChangeRegion = (selected: string) => {
    setRegion(parseInt(selected.valueOf().toString(), 10))
    setSelectedCity('')
    onCityChange('')
    onMarketChange(selected)
  }

  const onChangeCity = (selected: string) => {
    setSelectedCity(cities?.find((ct) => ct.id === parseInt(selected.valueOf().toString(), 10))?.name ?? '')
    onCityChange(selected)
  }

  useEffect(() => {
    const zones = region ? markets.find((mk) => mk.id === region)?.zones : []
    setCities(zones)
  }, [region])

  return (
    <>
      <div className={`${cssPrefix}container-bottom`}>
        <div className={`${cssPrefix}container-bottom-left`}>
          <Controller
            control={control}
            name="firstName"
            render={({ value, onChange }) => (
              <TextField
                value={capitalize(value)}
                label="Nombre"
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ value, onChange }) => (
              <TextField
                label="Apellido"
                value={capitalize(value)}
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="dni"
            render={({ value, onChange }) => (
              <TextField
                label="Rut"
                value={value}
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={({ value, onChange }) => (
              <TextField
                label="Correo Electrónico"
                value={value}
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={`${cssPrefix}container-bottom-right`}>
          <div className={`${cssPrefix}password-container`}>
            <TextField label="Contraseña" readOnly value="****" />
            <div className={`${cssPrefix}password-reset`}>
              <Anchor
                className={`${cssPrefix}reset-link`}
                onClick={() => (!isLoading ? navigate(`/new-password`) : null)}
              >
                <span>Restablecer</span>
              </Anchor>
            </div>

            <Controller
              control={control}
              name="market"
              render={() => (
                <Select
                  className={`${cssPrefix}market-selector`}
                  label="Región"
                  placeholder={markets.find((mk) => mk.id === region)?.name || ''}
                  onSelect={(selected) => onChangeRegion(selected)}
                  options={Object.values(markets).map((val) => ({
                    value: val.id,
                    label: val.name,
                  }))}
                />
              )}
            />

            <Controller
              control={control}
              name="city"
              render={() => (
                <Select
                  className={`${cssPrefix}city-selector`}
                  label="Comuna"
                  placeholder={selectedCity}
                  value={selectedCity}
                  onSelect={(selected) => onChangeCity(selected)}
                  disabled={!region}
                  options={Object.values(cities ?? { value: 'empty', label: 'empty' }).map((val) => ({
                    value: val.id,
                    label: val.name,
                  }))}
                />
              )}
            />

            <div className={`${cssPrefix}birthdate-container`}>
              <div className={`${cssPrefix}birthdate-label`}>
                <span>Fecha de nacimiento</span>
              </div>
              <div className={`${cssPrefix}birthdate-fields`}>
                <TextField value={bdate.date} disabled className={`${cssPrefix}birthdate-fields-date`} />
                <TextField
                  value={capitalize(toMonthName(parseInt(bdate.month, 10)))}
                  disabled
                  className={`${cssPrefix}birthdate-fields-month`}
                />
                <TextField value={bdate.year} disabled className={`${cssPrefix}birthdate-fields-year`} />
              </div>
            </div>
            <div className={`${cssPrefix}submit-container`}>
              <Button
                isLoading={isLoading}
                disabled={isLoading || !isValid}
                isDisabled={Object.keys(dirtyFields).length === 0 || !isValid}
                className={`${cssPrefix}submit-button`}
                onClick={() => {
                  return globalQuantity > 0 ? setAlertState({ ...alertState, isOpenSubmit: true }) : handleSubmit()
                }}
              >
                Guardar cambios
              </Button>
            </div>
            <div className={`${cssPrefix}error-status`}>
              <InfoMessage className={`${cssPrefix}message`} isHidden={!apiError} message={apiError || undefined} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditChile
