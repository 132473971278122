import React, { useEffect, useState } from 'react'
import {
  Anchor,
  Button,
  capitalize,
  InfoMessage,
  Select,
  TextField,
  toMonthName,
  useAuth,
  DocumentType,
  Market,
  Zone,
} from '@ecommerce/shared'
import { Controller, useFormContext } from 'react-hook-form'
import { CheckboxOption } from '@ecommerce/shared/src/components/organisms/AuthWidget/styled'
import Checkbox from '@ecommerce/shared/src/components/atoms/Checkbox'
import { navigate } from 'gatsby'
import useContentfulGlobal from '../../../../hooks/useContentfulGlobal'
import secrets from '../../../../config/secrets'

const { documentTypes } = secrets

interface Props {
  isLoading: boolean
  markets: Market[]
  onCityChange: (selected: string) => {}
  cssPrefix: string
  bdate: {
    date: string
    month: string
    year: string
  }
  globalQuantity: number
  apiError: string | null
  setAlertState: ({ isOpenSignOut, isOpenSubmit }: { isOpenSignOut: boolean; isOpenSubmit: boolean }) => void
  alertState: {
    isOpenSignOut: boolean
    isOpenSubmit: boolean
  }
  handleSubmit: () => void
  selectedDocumentType: string
  setSelectedDocumentType: (selectDocumentType: string) => void
}

const EditBolivia = ({
  alertState,
  setAlertState,
  apiError,
  cssPrefix,
  markets,
  isLoading,
  bdate,
  onCityChange,
  globalQuantity,
  handleSubmit,
  selectedDocumentType,
  setSelectedDocumentType,
}: Props) => {
  const { accountUpdateEnable } = useContentfulGlobal()

  const methods = useFormContext()

  const { control, setValue, formState, watch, trigger, getValues, setError } = methods

  const { dirtyFields, isValid, errors } = formState

  const { state: authState } = useAuth()

  const { dni, city } = authState

  const lastName = watch('lastName')
  const firstName = watch('firstName')
  const billingName = watch('billingName')
  const documentType = watch('documentType')

  const [isBillingName, setIsBillingName] = useState(false)
  const [enableInputs, setEnableInputs] = useState(accountUpdateEnable || false)
  const [selectedCity, setSelectedCity] = useState<Zone>()
  const [cities, setCities] = useState<Zone[]>()
  const [isCheckingDni, setIsCheckingDni] = useState(false)

  useEffect(() => {
    if (!enableInputs) {
      if (isBillingName) {
        setValue('billingName', `${firstName} ${lastName}`, { shouldDirty: true })
      } else {
        setError('billingName', {
          type: 'manual',
          message: `Debes completar este campo.`,
        })
        setValue('billingName', '', { shouldDirty: true })
      }
      trigger('billingName')
    }
  }, [isBillingName])

  useEffect(() => {
    setSelectedDocumentType(documentType)
    const zones = markets.map((m) => m.zones.map((z) => z)).flat()
    setCities(zones)
    setSelectedCity(zones?.find((z) => z.id === city))
  }, [])

  useEffect(() => {
    setSelectedCity(cities?.find((c) => c.id === city))
  }, [city, cities])

  const onBillingNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue('billingName', value, { shouldDirty: true })
    await trigger('billingName')
  }

  return (
    <>
      <div className={`${cssPrefix}container-bottom`}>
        <div className={`${cssPrefix}container-bottom-left`}>
          <Controller
            control={control}
            name="firstName"
            render={({ value, onChange }) => (
              <TextField
                status={!errors.firstName ? undefined : 'error'}
                errorMessage={errors.firstName?.message}
                value={capitalize(value)}
                label="Nombre"
                disabled={enableInputs}
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ value, onChange }) => (
              <TextField
                label="Apellido"
                status={!errors.lastName ? undefined : 'error'}
                errorMessage={errors.lastName?.message}
                value={capitalize(value)}
                disabled={enableInputs}
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          {!enableInputs && (
            <CheckboxOption className={`${cssPrefix}checkbox`}>
              <Controller
                control={control}
                name="isBillingName"
                render={() => (
                  <Checkbox
                    disabled={enableInputs}
                    onChange={() => setIsBillingName(!isBillingName)}
                    className="CheckboxOption__input"
                  />
                )}
              />
              <p className="CheckboxOption__label">Utilizar mi nombre y apellidos para facturas.</p>
            </CheckboxOption>
          )}

          {((billingName && enableInputs) || (!enableInputs && !isBillingName)) && (
            <TextField
              label="Nombre para facturas"
              status={!errors.billingName ? undefined : 'error'}
              errorMessage={errors.billingName?.message}
              value={getValues().billingName || ''}
              disabled={enableInputs}
              className={`${cssPrefix}container-bottom-left-field`}
              onChange={onBillingNameChange}
            />
          )}

          <DocumentType
            selectedDocumentType={selectedDocumentType}
            isVisible={!enableInputs}
            setSelectedDocumentType={setSelectedDocumentType}
            types={documentTypes}
            setIsCheckingDni={setIsCheckingDni}
            dni={dni}
          />
        </div>

        <div className={`${cssPrefix}container-bottom-right`}>
          <Controller
            control={control}
            name="email"
            render={({ value, onChange }) => (
              <TextField
                label="Correo Electrónico"
                status={!errors.email ? undefined : 'error'}
                errorMessage={errors.email?.message}
                value={value}
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          <div className={`${cssPrefix}password-container`}>
            {enableInputs && (
              <>
                <TextField label="Contraseña" readOnly value="****" />
                <div className={`${cssPrefix}password-reset`}>
                  <Anchor
                    className={`${cssPrefix}reset-link`}
                    onClick={() => (!isLoading ? navigate(`/new-password`) : null)}
                  >
                    <span>Restablecer</span>
                  </Anchor>
                </div>
              </>
            )}

            <Controller
              control={control}
              name="city"
              render={() => (
                <Select
                  className={`${cssPrefix}city-selector`}
                  label="Ciudad"
                  status={!errors.city ? undefined : 'error'}
                  errorMessage={errors.city?.message}
                  key={selectedCity?.id}
                  disabled={!enableInputs}
                  placeholder={selectedCity?.name || 'Selecciona tu ciudad'}
                  onSelect={(selected) => onCityChange(selected)}
                  options={Object.values(cities ?? []).map((val) => ({
                    value: val.id,
                    label: val.name,
                  }))}
                />
              )}
            />

            <div className={`${cssPrefix}birthdate-container`}>
              <div className={`${cssPrefix}birthdate-label`}>
                <span>Fecha de nacimiento</span>
              </div>
              <div className={`${cssPrefix}birthdate-fields`}>
                <TextField value={bdate.date} disabled className={`${cssPrefix}birthdate-fields-date`} />
                <TextField
                  value={capitalize(toMonthName(parseInt(bdate.month, 10)))}
                  disabled
                  className={`${cssPrefix}birthdate-fields-month`}
                />
                <TextField value={bdate.year} disabled className={`${cssPrefix}birthdate-fields-year`} />
              </div>
            </div>
            <div className={`${cssPrefix}submit-container`}>
              <Button
                isLoading={isLoading || isCheckingDni}
                disabled={(Object.keys(dirtyFields).length === 0 || isLoading || isCheckingDni) && !enableInputs}
                isDisabled={(Object.keys(dirtyFields).length === 0 || !isValid || isCheckingDni) && !enableInputs}
                className={`${cssPrefix}submit-button`}
                onClick={() => {
                  return globalQuantity > 0 && enableInputs
                    ? setAlertState({ ...alertState, isOpenSubmit: true })
                    : handleSubmit()
                }}
              >
                Guardar cambios
              </Button>
            </div>
            <div className={`${cssPrefix}error-status`}>
              <InfoMessage className={`${cssPrefix}message`} isHidden={!apiError} message={apiError || undefined} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditBolivia
